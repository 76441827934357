import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EventContext, UserContext, TimeContext, UserType } from "context";
import { PreCampaign } from "./PreCampaign";
import { Sweeps, PostSweeps, Ineligible } from "./Register";
import { PreShow } from "./PreShow";
import { Show } from "./Show";
import { PostShow } from "./PostShow";
import { Voting } from "./Voting";
import { PostCampaign } from "./PostCampaign";
import { AdminControls, RingSpinner } from "Components";

export const Root = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const {
    user,
    userType,
    checkUserVoteExist,
    getUserRegistration,
  } = useContext(UserContext);
  const history = useHistory();
  const [adminView, setAdminView] = useState("");
  const [voteExists, setVoteExists] = useState(false);
  const [ineligible, setIneligible] = useState(false);
  const [regPart1Complete, setRegPart1Complete] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const viewHistory = urlParams.get("view");

  useEffect(() => {
    checkUserVoteExist().then((exists) => {
      setVoteExists(exists);
    });

    getUserRegistration().then((data) => {
      if (data?.formPart === 1) setRegPart1Complete(true);
    });
  });

  useEffect(() => {
    if (userType === UserType.ADMIN && !adminView) {
      setAdminView(viewHistory ? viewHistory : "precampaign");
      !viewHistory && history.push(`?view=precampaign`);
    }
    // eslint-disable-next-line
  }, [userType]);

  if (!user) return <RingSpinner />;

  const adminViews = [
    "precampaign", // 0
    "sweeps", // 1
    "sweeps-part2", // 2
    "sweeps-registered", // 3
    "postsweeps", // 4
    "postsweeps-registered", // 5
    "ineligible", // 6
    "voting", // 7
    "voted", // 8
    "preshow", // 9
    "show", // 10
    "postshow", // 11
    "postcampaign", // 12
  ];

  delete adminViews[1];
  delete adminViews[2];
  delete adminViews[3];
  delete adminViews[4];
  delete adminViews[5];
  delete adminViews[6];

  if (!event?.meta?.hasVoting) {
    delete adminViews[7];
    delete adminViews[8];
  }

  if (!event?.meta?.hasLivestream) {
    delete adminViews[9];
    delete adminViews[10];
    delete adminViews[11];
  }

  const getAdminView = () => {
    switch (viewHistory || adminView) {
      case "precampaign":
        return <PreCampaign />;
      case "voting":
        return <Voting />;
      case "voted":
        return <Voting hasVoted />;
      case "sweeps":
        return <Sweeps setIneligible={setIneligible} />;
      case "sweeps-part2":
        return <Sweeps formStep={1} />;
      case "sweeps-registered":
        return <Sweeps registered />;
      case "postsweeps":
        return <PostSweeps />;
      case "postsweeps-registered":
        return <PostSweeps registered />;
      case "ineligible":
        return <Ineligible />;
      case "preshow":
        return <PreShow />;
      case "show":
        return <Show />;
      case "postshow":
        return <PostShow />;
      case "postcampaign":
        return <PostCampaign />;
      default:
        return null;
    }
  };

  if (userType === UserType.ADMIN) {
    return (
      <>
        <AdminControls
          views={adminViews}
          selectedView={adminView}
          setAdminView={setAdminView}
        />
        {getAdminView()}
      </>
    );
  }

  return <PostCampaign />
};
