import React, { useContext, useState } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext, TempContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { RegistrationForm } from "Components/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "Components/Assets/logo.png";
import heroImg from "Components/Assets/heroImg.png";
import { useMediaQuery } from "hooks";
import { VideoPlayer } from "Components";


export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event?.meta?.storageDirectory;

    const isDesktop = useMediaQuery("(min-width: 950px)");
    console.log(isDesktop);
    // logo
    const { brand: brandLogo, hero, link } = event?.logos;
    let brandLogoImg = `${api}/o/${storageDirectory}%2F${brandLogo?.image}?alt=media`;
    //  let heroImg = `${api}/o/${storageDirectory}%2F${hero?.image}?alt=media`;
    if (registered) formStep = event?.register.sweeps.length - 1;

    const {
      footerHeading,
      maxPolicy,
      brandDisclaimer,
      downloadLink,
      downloadLinkSp
    } = event.footer;

    // registration content
    const {
      heading,
      subHeading,
      subHeadingPostReg,
      subBodyPostSweep,
      body,
      bodyPostSweep,
      bodyPostReg,
      formHeading,
      formLegal,
      submit,
      pageCheck,
    } = sweeps
      ? event?.register.sweeps[formStep]
      : event?.register.postSweeps[formStep];

    // ineligible content
    const {
      heading: ineligibleHeading,
      body: ineligibleBody,
    } = event?.register;

    // music video background
    const {
      background: musicVideoBg,
      label: musicVideoLabel,
      video: musicVideoLink,
    } = event?.callToActions.musicVideo;
    let videoBgDesktop = `${api}/o/${storageDirectory}%2F${musicVideoBg.desktop}?alt=media`;
    let videoBgMobile = `${api}/o/${storageDirectory}%2F${musicVideoBg.mobile}?alt=media`;

    // brand cta
    const {
      btnLink: brandCTALink,
      btnText: brandCTAText,
      heading: brandCTAHeading,
    } = event?.callToActions.brand;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
    } = event?.callToActions.brand.background;
    let brandBgDesktop = `${api}/o/${storageDirectory}%2F${brandBgD}?alt=media`;
    let brandBgMobile = `${api}/o/${storageDirectory}%2F${brandBgM}?alt=media`;

    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event?.callToActions.brand.product;
    let productDesktop = `${api}/o/${storageDirectory}%2F${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/o/${storageDirectory}%2F${brandProductMobile}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      headingMobile: postCampaignHeadingMobile,
      subHeading: postCampaignSubHeading,
      body: postCampaignBody,
    } = event?.postCampaign;
    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event?.callToActions.brandSecondary;

    const { desktop, mobile} = event?.callToActions?.heroVideo;
    const desktopVideoUrls = desktop?.map(
      (url) => `${api}/o/${storageDirectory}%2F${url}?alt=media`
    );

    const mobileVideoUrls = mobile?.map(
      (url) => `${api}/o/${storageDirectory}%2F${url}?alt=media`
    );

    const currentVideoUrl = isDesktop ? desktopVideoUrls : mobileVideoUrls

    const logoClickHandler = (link) => {
      logClick({ label: "Header Logo", url: link });
    };

    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        //  showFooter
        registered={registered}
        postCampaign={postCampaign}
      >
        <Container postCampaign={postCampaign}>
          <LogoBox>
            <StyledLink
              href={brandLogo.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => logoClickHandler(brandLogo.link)}
            >
              {brandLogoImg && <Logo src={logo} />}
            </StyledLink>
          </LogoBox>
          <ContainerPadding>
            {/* ===== POST-CAMPAIGN HEADINGS ===== */}
            {isDesktop ? (
              <MainHeading>{ReactHtmlParser(postCampaignHeading)}</MainHeading>
            ) : (
              <MainHeading>{ReactHtmlParser(postCampaignHeadingMobile)}</MainHeading>
            )}
            {postCampaignBody && (
              <Body>{ReactHtmlParser(postCampaignBody)}</Body>
            )}
            {postCampaignSubHeading && (
              <SubHeading>{ReactHtmlParser(postCampaignSubHeading)}</SubHeading>
            )}
            <StyledButton onClick={() => logoClickHandler(link)}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {" "}
                Watch Video{" "}
              </a>
            </StyledButton>
          </ContainerPadding>

          <Footer>
            {footerHeading && (
              <HeadingPostCampaign>
                {ReactHtmlParser(footerHeading)}
              </HeadingPostCampaign>
            )}
            <ButtonContainer>
              <StyledFooterButton>
                <a
                  href={downloadLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download{" "}
                </a>
              </StyledFooterButton>
              <StyledFooterButton>
                <a
                  href={downloadLinkSp}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Descargar{" "}
                </a>
              </StyledFooterButton>
            </ButtonContainer>
            {brandDisclaimer && (
              <SubHeadingPostCampaign>
                {ReactHtmlParser(brandDisclaimer)}
              </SubHeadingPostCampaign>
            )}
            {maxPolicy && (
              <SubHeadingPostCampaign>
                {ReactHtmlParser(maxPolicy)}
              </SubHeadingPostCampaign>
            )}
          </Footer>
        </Container>
        <VideoContainer
          play
          path={currentVideoUrl}
          loop
          mute
          thumbnail={heroImg}
          isBackground
        />
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
  background-position: center;
  z-index: 2;


  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column;
    padding: 0px 0px 0px 0px;
  }

  @media (max-width: 950px) {
    background-position: 70% 5%;
  }
`;

const Content = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 33%;
    max-width: 400px;
    padding: 50px 0px;
  }

  @media (min-width: 950px) {
    min-height: 90vh;
  }

  @media (max-width: 950px) {
    padding: 0 15px;
  }
`;

const ContainerPadding = styled.div`
  display: flex;
  flex-direction: column;
  width: 60vw;
  align-items: center;

  @media (max-width: 950px) {
    width: 85vw;
  }
`;

const VideoContainer = styled(VideoPlayer)`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

    video {
      object-fit: cover;
    }
`;

const LogoBox = styled.div`
  margin: 25px 0 0 25px;
  display: flex;
  width: 100%;
  max-width: 100px;
  align-self: flex-start;

  @media (max-width: 950px) {
    padding: 5px 5px;
  }
`;

const Footer = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 0;
  flex-direction: column;
  align-items: center;
  margin-top: 20vw;
  width: 100%;
  height: 20%;
  margin: 0;
  background-color: rgba(219, 0, 7, 0.7);
`;

const StyledLink = styled.a`
  width: 80%;
  max-width: 100px;

  @media (max-width: 950px) {
    max-width: 50px;
  }
`;

const StyledButton = styled(ButtonPrimary)`
  width: 30%;
  max-width: 150px;
  font-size: 0.9rem;
  padding: 12px 1px 10px 1px;
  margin-top: 5px;

  @media (max-width: 950px) {
    font-size: 0.8rem;
    width: 50%;
    max-width: 150px;
  }

  & > a {
    color: black;
    text-decoration: none;
  }
`;

const StyledFooterButton = styled(ButtonPrimary)`
  margin: 0 10px 15px 15px;
  font-size: 0.8rem;
  padding: 12px 20px 10px 20px;

  & > a {
    color: black;
    text-decoration: none;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const StyledLinkForward = styled(Link)`
  color: #000;
  width: 70%;
  background: #ffbc0d;
  padding: 10px 10px 8px 10px;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-weight: 500;

  @media (min-width: 950px) {
    min-width: 250px;
  }

  @media (max-width: 950px) {
    font-size: 0.6em;
    margin-right: 7px;
    padding: 10px 0px 8px 8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 950px) {
    align-self: center;
  }
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
`;

const MainHeading = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: -10px;
  font-size: 3rem;
  text-shadow: 1px 2px 2px black;

  & > span {
    display: block;
    font-size: 2.5rem;

    @media (max-width: 950px) {
      font-size: 2rem;
    }

    img {
      margin-bottom: -10px;
      display: inline-block;

      @media (max-width: 950px) {
        width: 280px;
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 950px) {
    font-size: 2.8rem;
    line-height: 120%;
  }
`;

const Heading = styled.h2``;

const SubHeading = styled.h3`
  color: white;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 0px;
  text-shadow: 1px 2px 2px black;

  @media (max-width: 950px) {
    font-size: 0.85em;
  }
`;

const Body = styled.p`
  color: white;
  text-align: center;
  font-size: 1rem;
  padding: 0 40px;
  line-height: 140%;
  text-shadow: 1px 2px 2px black;

  @media (max-width: 950px) {
    padding: 0 50px;
    font-size: 0.75rem;
    line-height: 150%;
  }

  & > span {
    display: inline-block;
    font-size: 1.4rem;

    @media (max-width: 950px) {
      font-size: 1rem;
    }
`;
const BodyPostSweep = styled.p`
  color: #292929;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 500;
`;

const SubBodyPostSweep = styled.p`
  font-size: 1rem;
  font-weight: 500;
  padding-right: 40px;
  line-height: 140%;
  color: white;
`;
const HeadingPostCampaign = styled(Heading)`
  color: white;
  text-align: center;
  font-size: 1.2rem;
  margin: 15px 0 10px 0;

  @media (max-width: 950px) {
    font-size: 0.75rem;
  }
`;

const SubHeadingPostCampaign = styled.h3`
  font-weight: 400;
  font-size: 0.6rem;
  color: white;
  text-align: center;
  line-height: 90%;
  margin-bottom: 5px;

  @media (max-width: 950px) {
    line-height: 100%;
    font-size: 0.47rem;
    font-weight: 100;
    font-family: "Speedee_Reg";
  }

  & > a {
    color: white;
  }
`;

const BodyPostCampaign = styled(Body)`
  color: white;
  text-align: center;
  @media (max-width: 950px) {
    font-size: 0.85rem;
  }
`;

const topBorder = `
  border-top: 0px solid #707070;
  padding-top: 24px;
  margin-top: 24px;
`;

const PoweredBy = styled.div`
  ${topBorder}
`;

const BrandSecondaryCTA = styled.div`
  ${topBorder}
`;

const ArtistContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ArtistContentTop = styled.div`
  display: flex;
`;

const BrandImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.mobile});
  background-position: bottom;
  background-size: cover;
  padding-top: 56.25%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktop});
    background-position: center;
  }

  @media (max-width: 950px) {
    padding-top: 80%;
    margin-bottom: -30px;
    margin-top: -30px;
  }
`;

const HeroVideoMobile = styled.div`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const HeroVideoDesktop = styled.div`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: flex;
    width: 66%;
  }
`;

const MusicVideo = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 1 1 0%;
    padding: 0;
  }
`;

const MobileImage = styled.img`
  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const DesktopImage = styled.img`
  display: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
  }
`;

const BrandCTA = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    position: absolute;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    & > img {
      width: 150%;
    }
  }
`;

const BrandCTAHeading = styled.h3`
  color: #ffffff;
  padding: 0 20px;
  margin: 0 0 40px;
  z-index: 1;
`;

const Label = styled.div`
  background-color: rgba(0, 9, 91, 0.9);
  color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  text-decoration: none;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    font-size: 12px;
  }
`;
